<template>
    <div>
        <div class="container box-out">
            <p class="title-header d-flex justify-content-between">
                <span>Chi tiết lớp học</span>
                <router-link
                    class="font-weight-bold font-size-3 text-white btn btn-primary"
                    :to="{ name: 'team-class' }"
                ><i class="text-white el-icon-collection-tag"></i> Quản lý app HV
                </router-link>
            </p>
            <hr>
            <div v-if="lessonList.length > 0" class="row box-full">
                <Lesson
                    v-for="item in lessonList"
                    @attendance-show="showModalAttendance"
                    @mark-homeWork="openMarkHomework"
                    @give-gift="openDialogGiveGift"
                    @comment="checkOpenDialogComment"
                    @content="openDialogContentHomeWork"
                    :lesson="item"
                    :lessonList="lessonList"
                    :key="item.id">
                </Lesson>
            </div>
            <div v-else>
                <h3 class="text-center">Lớp học không có lộ trình</h3>
            </div>

            <!-- Dialog tặng quà -->
            <el-dialog class="gift" :title="gift_data.dialog.title" :visible.sync="gift_data.dialogGiveGift">
                <give-gift v-loading="gift_data.isLoadingGiveGift" :listStudentAttendance="gift_data.listStudentAttendance"
                    :studentGetGift="gift_data.studentGetGift" :dialogGiveGift="gift_data.dialogGiveGift"
                    @select-student="selectStudent" @radio="radioType">
                </give-gift>
                <span v-if="!gift_data.isLoadingGiveGift && !clickAttendanceAgain" slot="footer" class="dialog-footer">
                    <el-button :disabled="gift_data.disableSubmit" :loading="gift_data.loadingSubmit"
                        style="color: #fff; background-color: #500DA7;" v-if="gift_data.studentGetGift.length === 0"
                        @click="giveGift">Xác nhận</el-button>
                    <el-button style="color: #020202; background-color: #E6E6E6;"
                        @click="gift_data.dialogGiveGift = false">Đóng</el-button>
                </span>


            </el-dialog>

            <!--  Tặng quà thành công -->
            <el-dialog width="30%" :title="gift_data.dialog.title" class="responsive w-30"
                :visible.sync="gift_data.dialogGiftSuccess">
                <gift @close="closeDialogGiveSucess" v-if="gift_data.dialogGiftSuccess" :nameMember="gift_data.name"></gift>
            </el-dialog>

            <!--  Nhận xét -->
            <el-dialog width="60%" :title="comment_data.dialog.title" class="responsive"
                :visible.sync="comment_data.dialogComment">
                <comment-student @close="comment_data.dialogComment = false" :comments="comment_data.comments"
                    :type_comment="comment_data.dialog.type_comment" :time_comment="comment_data.time_comment"
                    :checkError="comment_data.checkError" @changeComment="changeComment" @postComment="postComment"
                    @postCommentPhuDao="postCommentPhuDao" @changeClassify="changeClassify">
                </comment-student>
            </el-dialog>

            <!-- Dialog Điểm danh -->
            <el-dialog id="el-dialog-Poin" :title="`Điểm danh: Buổi ${lessonNumber}`" :visible.sync="isShowModalAttendance"
                width="1148px" height="900px" :before-Open="beforeOpen">
                <div v-loading="loadingAttendance" class="modal-check-poin w-100">
                    <div class="row">
                        <div class="example-preview table-responsive overflow-auto mt-3">
                            <div class="d-flex justify-content-end mb-2" v-if="use_update">Cập nhật lúc: {{ use_update }}
                            </div>
                            <table class="table table-hover table-bordered table-vertical-center b-table">
                                <thead>
                                    <tr>
                                        <th scope="col" class="min-w-100px text-center">Họ và tên</th>
                                        <th scope="col" class="min-w-100px text-center">Mã học viên</th>
                                        <th scope="col" class="min-w-100px text-center">Số điện thoại</th>
                                        <th scope="col" class="min-w-100px text-center">Có mặt</th>
                                        <th scope="col" class="min-w-100px text-center">Vắng</th>
                                        <th scope="col" class="min-w-100px text-center">Có phép</th>
                                        <th scope="col" class="min-w-100px text-center">Lý do</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <Attendance v-for="attendance in attendanceList" @changeAttendance="changeAttendance"
                                        :isShowModalAttendance="isShowModalAttendance" :attendance="attendance"
                                        :key="attendance.id" :clickAttendanceAgain="clickAttendanceAgain"
                                        :isAttendanceAgain="isAttendanceAgain"></Attendance>
                                </tbody>
                            </table>
                        </div>

                        <span slot="footer" class="footer-checkpoin w-100% d-flex justify-content-end">
                                <el-button size="medium"
                                    v-if="isEditAttendance && currentUser.user.permission.includes(permission.SUA_DIEM_DANH) && !show_edit"
                                    :style="`color: #fff; background-color:#3699FF;`"
                                    @click="show_edit = true; clickAttendanceAgain = true">
                                    Sửa điểm danh
                                </el-button>


                            <el-button v-if="show_edit" size="medium"
                                    type="primary"
                                    style="color: white;  background-color:#0e6cda"
                                    @click="editAttendancePost">
                                    Xác nhận
                            </el-button>
                            
                            <el-button style="color: #020202; background-color: #E6E6E6;"
                                @click="closeDialogAttendance">Đóng</el-button>
                            <el-button size="medium" :loading="isLoadingSubmitAttendance" :disabled="isSubmitAttendance"
                                v-if="(isAttendance && !show_edit && !isEditAttendance || (!isAttendance && isAttendanceAgain && clickAttendanceAgain))"
                                :style="`color: #fff; background-color:${isSubmitAttendance ? '#8D61C6' : '#500DA7'};`"
                                :class="`${isSubmitAttendance ? 'disable-hard' : ''}`"
                                @click="attendancePost">
                                Điểm danh</el-button>
                            <el-button size="medium"
                                v-if="(!isAttendance && isAttendanceAgain) && !clickAttendanceAgain"
                                :style="`width: auto;padding: 0 20px 0 20px; color: #fff; background-color: #1BC5BD`"
                                @click="attendanceAgain">
                                Điểm danh bổ sung</el-button>
                        </span>
                    </div>
                </div>


            </el-dialog>

            <!-- Dialog nội dung chấm bài tập về nhà   -->
            <el-dialog style="overflow-x:auto;" id="el-dialog-Poin" :title="`Bài tập về nhà buổi ${lessonNumber}`"
                :visible.sync="isMarkHomeWork" width="1200px" :before-Open="beforeOpen">
                <hr>
                <!-- dialog list homework student -->
                <div v-loading="loadingMarkHomework" class="modal-fillter-poin table table-bordered">
                    <div class="example-preview table-responsive overflow-auto mt-3">
                        <table :aria-busy="isBusy" class="table table-hover table-bordered table-vertical-center b-table"
                            style="text-align: center">
                            <thead>
                                <tr>
                                    <th scope="col" class="min-w-100px">Họ và tên</th>
                                    <th scope="col" class="min-w-100px">Mã học viên</th>
                                    <th scope="col" class="min-w-100px">Số điện thoại</th>
                                    <th scope="col" class="min-w-100px">Trạng thái</th>
                                    <th scope="col" class="min-w-120px">Hình thức chấm</th>
                                    <th class="text-center">Thời gian GV chấm bài</th>
                                    <th scope="col" class="min-w-120px">Hành động</th>
                                </tr>
                            </thead>
                            <tbody>
                                <HomeWork v-for="(item, index) in homeWorkList" :learning_schedule_id="learning_schedule_id"
                                    :lessonNumber="lessonNumber" @action-mark-homework="actionMarkHomework"
                                    :homeWork="item" :key="index"></HomeWork>
                            </tbody>
                        </table>
                    </div>
                </div>
            </el-dialog>

            <!-- dialog mark esay-->
            <el-dialog id="el-dialog-Poin" title="Chấm bài tự luận" :visible.sync="isMarkEssay" width="1148px"
                height="900px" :before-Open="beforeOpen">
                <hr>
                <home-work-exam v-loading="mark_essay.isLoadingMarkEssay" v-if="isMarkEssay" @close="closeDialogMarkEssay()"
                    type-show="mark" @markSuccess="markSuccess" :student="mark_essay.student"
                    :data="mark_essay.data"></home-work-exam>
            </el-dialog>
            <el-dialog :title="titleContent" class="responsive" :visible.sync="dialogContent">
                <content-lesson @close="closeDialog()" :item="contentLesson" @show="show">
                </content-lesson>
            </el-dialog>
            <el-dialog title="Lịch sử mở slide" :visible.sync="showHistory" width="30%">
                <history-open-slide :dataTable="dataTimeOpenSlide"></history-open-slide>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="showHistory = false">Đóng</el-button>
                </span>
            </el-dialog>
            <el-dialog title="Nhập lý do nghỉ học" :visible.sync="dialogReason">
                <table class="table table-bordered m-0">
                    <thead>
                    <tr>
                        <th> Họ và tên</th>
                        <th> Mã học viên</th>
                        <th class="text-center"> Lý do</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in studentRegister" :key="index">
                        <td>{{item.user_name}}</td>
                        <td>{{item.user_code}}</td>
                        <td>
                            <input type="text" class="form-control" style="font-size: 14px" v-model="item.reason">
                            <span v-if="!item.reason" class="text-danger">Lý do không được để trống</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end mt-4">
                    <el-button class="btn btn-outline-default ml-2" @click="dialogReason = false">Đóng</el-button>
                    <el-button class="btn btn-outline-default ml-2 " v-loading="loadingSubmit" type="primary" @click="submint('')">Xác nhận</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import Lesson from './components/Lesson.vue';
import Attendance from './components/Attendance.vue';
import HomeWork from './components/HomeWork.vue';
import HomeWorkExam from "./components/homeworkexam.vue";
import GiveGift from "./components/give-gift";
import Gift from "./components/gift";
import { mapGetters } from "vuex";
import CommentStudent from "./components/comment-student";
import ContentLesson from "./components/content-lesson";
import HistoryOpenSlide from "./components/historyOpenSlide"
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
    SCHEDULE_CLASS, ATTENDANCE_LESSION, ACTION_ATTENDANCE_ADMIN, ADMIN_HOMEWORK, ADMIN_GET_MARK_HOMEWORK,
    STUDENT_HAS_GIFT, STUDENT_HAS_ATTENDANCE, GIVE_GIFT, POST_COMMENT, GET_TIME_OPEN_SLIDE
} from "@/core/services/store/course/classes.module";
import moment from "moment-timezone";
export default {

    components: {
        Lesson,
        Attendance,
        HomeWork,
        HomeWorkExam,
        GiveGift,
        Gift,
        CommentStudent,
        ContentLesson,
        HistoryOpenSlide
    },

    name: "ClassDetail",
    data() {
        return {
            isMarkHomeWork: false,
            isShowModalAttendance: false,
            isMarkEssay: false,
            lessonNumber: 0,
            startTimeLesson: '',
            lessonType: '',
            greaterComingLesson: false,
            starTime: '',
            checkPointDetail: {},
            checkPointId: 0,
            checkFirstArray: {},
            checkBoxArray: {},
            lessonList: [],
            attendanceList: [],
            attendanceSubmit: {
                data: []
            },
            isAttendance: true,
            isAttendanceAgain : false,
            clickAttendanceAgain : false,
            homeWorkList: [],
            loadingAttendance: true,
            loadingMarkHomework: true,
            loadingEditMarkHomework: true,
            isSubmitAttendance: true,
            isLoadingSubmitAttendance: false,
            isLoadingAttendanceAgain: false,
            isBusy: false,
            learning_schedule_id: null,
            mark_essay: {
                isLoadingMarkEssay: true
            },
            // action tặng quà nha
            gift_data: {
                dialog: {
                    title: ''
                },
                isLoadingGiveGift: true,
                dialogGiftSuccess: false,
                dialogGiveGift: false,
                listStudentAttendance: [],
                studentGetGift: [],
                student_id: 0,
                type_radio: 1,
                loadingSubmit: false,
                disableSubmit: false
            },

            // Nhận xét nè
            comment_data: {
                dialog: {
                    title: '',
                    type_comment: ''
                },
                dialogComment: false,
                checkError: '',
                comments: [],
                time_comment: ''
            },
            use_update: '',
            contentLesson: {},
            dialogContent: false,
            showHistory: false,
            dataTimeOpenSlide: [],
            titleContent: '',
            lesson: 0,
            user_update_object: '',
            is_edit: false,
            isEditAttendance: true,
            show_edit: false,
            dialogReason: false,
            studentRegister: [],
            loadingSubmit: false
        }
    },
    async mounted() {
        await this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Sửa lỗi dạy học", route: 'manage-error' },
            { title: "Chi tiết lớp học" }
        ]);
        await this.getLesson();
    },
    computed: {
      checkShowPhase() {
        return this.getCurrentPhase !== undefined;
      },
      getCurrentPhase() {
        const phaseDefault = this.getPhaseDefault;
        return phaseDefault?.find(d => d.lesson === this.lesson);
      },
      getPhaseDefault() {
        const listDateLesson = this.lessonList?.map((date, index) => {
          return {
            index: index,
            lesson: date.lesson,
            date: date.date_only,
            type: date.type
          }
        })
        const phaseDefault = [6, 12, 18, 24, 28, 32, 36, 40]
        return phaseDefault.map(phase => {
          let current = listDateLesson?.find(lesson => lesson.lesson === phase)
          if (!current) {
            return null
          }
          if (current.type === 'homework') {
            return current
          }
          return listDateLesson?.find(lesson => lesson?.lesson === phase + 1)
        }).filter(d => d !== null && d !== undefined);
      },
      ...mapGetters(["currentUser"])
    },
    methods: {
        checkOpenDialogComment(item) {
            this.lesson = item.lesson
            this.$store.dispatch(ATTENDANCE_LESSION, {
                classroom_id: this.$route.params.id,
                lesson: item.lesson
            }).then(data => {
                  const attendanceNone = data.data.filter(item => item.attendance !== 1)
                  if(this.checkShowPhase || attendanceNone.length < data.data.length) {
                    this.$router.push({
                      name: 'schedule-class-comment',
                      params: {id: item.lesson},
                      query: {
                        classroom_id: this.$route.params.id,
                        phase: this.checkShowPhase
                      }
                    })
                  } else {
                    this.$message({
                      message: 'Giảng viên không cần thực hiện tác vụ nhận xét do tất cả học viên đều vắng học.',
                      type: 'error'
                    });
                  }
              }
            );
        },
        changeComment(item, value) {
            this.comment_data.comments.map((v, k) => {
                if (v.id == item.id) {
                    return this.comment_data.comments[k].comment = value;
                }
            })
        },
        changeClassify(item, value) {
            this.comment_data.comments.map((v, k) => {
                if (v.id == item.id) {
                    return this.comment_data.comments[k].classify = value;
                }
            })
        },
        postComment() {
            if (this.checkAllAlike()) {
                this.comment_data.checkError = 'Cảnh báo lỗi: Bạn đang chọn một loại nhận xét cho tất cả học viên!';
                return;
            }
            this.comment_data.checkError = '';
            if (this.checkAllLengthByClassify().length) {
                return;
            }
            this.$store.dispatch(POST_COMMENT, this.getPayloadPostComment()).then((res) => {
                if (!res.error) {
                    this.$message({
                        message: 'Nhận xét thành công',
                        type: 'success'
                    });
                    this.comment_data.dialogComment = false;
                } else {
                    this.$message.error('Oops, Đánh giá thất bại.');
                }
            });
        },
        checkAllAlike() {
            let comments = this.comment_data.comments;
            let count = 0;
            this.comment_data.comments.map(function (value) {
                if (comments[0].classify == value.classify) {
                    count++;
                }
            });
            if (this.comment_data.comments.length === count && this.comment_data.comments.length != 1) {
                return true
            }
            return false;
        },
        checkAllLengthByClassify() {
            let arr = [];
            this.comment_data.comments.filter(d => {
                if ([0, 1, 2, 3].includes(d.classify) && d.comment.length < 150 || d.classify == '') {
                    arr.push(d.user_code);
                }
            });
            return arr;
        },
        postCommentPhuDao() {
            let newData = this.getPayloadPostComment().data.map(item => {
                return { course_student_id: item.id, comment: item.comment }
            })
            let payload = {
                tutoring_lesson_id: this.item.id,
                data: newData,
            };
            this.$store.dispatch(POST_COMMENT_PHU_DAO, payload).then((res) => {
                if (!res.error) {
                    this.$message({
                        message: 'Nhận xét thành công',
                        type: 'success'
                    });
                    this.closeDialog();
                    // this.openDialog(this.item);
                } else {
                    this.$message.error('Oops, Đánh giá thất bại.');
                }
            });
        },
        getPayloadPostComment() {
            let data = [];
            this.comment_data.comments.map((value) => {
                data.push({
                    id: value.id,
                    comment: value.comment,
                    classify: value.classify,
                })
            });
            return {
                classroom_id: this.$route.params.id,
                lesson: this.item.lesson,
                data: data,
            };
        },
        // show dialog mark homework student
        showDialogMarkHomework(lession, learning_schedule_id) {
            this.learning_schedule_id = learning_schedule_id;
            this.homeWorkList = [];
            this.loadingMarkHomework = true;
            let idclass = this.$route.params.id;
            this.lessonNumber = lession;
            this.$store.dispatch(ADMIN_HOMEWORK, { classroom_id: idclass, lesson: lession, type: 'homework' }).then((res) => {
                if (!res.error) {
                    this.homeWorkList = res.data;
                    this.loadingMarkHomework = false;
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        openMarkHomework(lession,learning_schedule_id,center_id) {
            const routeData = this.$router.resolve({ name: "list_mark_homework",params: {id : this.$route.params.id},
            query: { classroom_id: this.$route.params.id, lesson: lession , learning_schedule_id: learning_schedule_id, center_id: center_id}});

            window.open(routeData.href, '_blank');
        },

        // active give gift
        async openDialogGiveGift(item) {
            this.gift_data.dialogGiveGift = true;
            this.gift_data.isLoadingGiveGift = true;
            this.gift_data.dialog.title = 'Tặng quà: Buổi ' + `${item.lesson}`;
            this.item = item;
            await this.$store.dispatch(STUDENT_HAS_ATTENDANCE, {
                classroom_id: this.$route.params.id,
                lesson: this.item.lesson
            }).then((res) => {
                if (!res.error) {
                    this.gift_data.listStudentAttendance = res.data;

                }
            });

            await this.$store.dispatch(STUDENT_HAS_GIFT, {
                classroom_id: this.$route.params.id,
                lesson: this.item.lesson
            }).then((res) => {
                if (!res.error) {
                    this.gift_data.studentGetGift = res.data;
                }
            });
            this.gift_data.isLoadingGiveGift = false;
        },

        selectStudent(student_data) {
            if (student_data) {
                if (this.gift_data.type_radio == 1) {
                    this.gift_data.disableSubmit = false;
                }
                this.gift_data.student_id = student_data.id;
                this.gift_data.clan_id = student_data.clan_id;
                this.gift_data.name = student_data.name;
            } else {
                if (this.gift_data.type_radio == 1) {
                    this.gift_data.disableSubmit = true;
                }
            }
        },
        radioType(type) {
            this.gift_data.type_radio = type;
            if (type == 2) {
                this.gift_data.student_id = 0;
                this.gift_data.disableSubmit = false;
            } else {
                this.gift_data.disableSubmit = true;
            }
        },
        giveGift() {
            this.gift_data.loadingSubmit = true;
            this.gift_data.disableSubmit = true;
            let params = {
                user_id: this.gift_data.student_id,
                user_clan_id: this.gift_data.clan_id,
                classroom_id: this.$route.params.id,
                lesson_id: this.item.lesson
            }
            let message = '';
            if (params.user_id == 0) {
                message = 'Thao tác thành công!'
            } else {
                this.gift_data.dialogGiftSuccess = true;
                message = 'Tặng quà thành công!'
            }
            this.$store.dispatch(GIVE_GIFT, params)
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: message,
                        showClose: true
                    });
                    this.gift_data.dialog.title = `Thưởng`;
                    this.gift_data.dialogGiveGift = false;
                    this.gift_data.disableSubmit = false;
                    this.gift_data.loadingSubmit = false;
                }).catch((response) => {
                    console.log("response", response);
                    this.$notify({
                        title: 'Warning',
                        message: `Serve đang bận, vui lòng thử lại sau: ${response.data.data.message}`,
                        type: 'warning'
                    });
                });


        },
        closeDialogGiveSucess() {
            this.gift_data.dialogGiftSuccess = false;
        },

        // action mark homework student
        actionMarkHomework(homework_student) {
            this.mark_essay.isLoadingMarkEssay = true;
            this.mark_essay.student = null;
            this.mark_essay.data = [];
                this.isMarkEssay = true;
                this.mark_essay.student = homework_student;
                let payload = {
                    classroom_id:homework_student.classroom_id,
                    course_student_id:homework_student.course_student_id,
                    overall_id:homework_student?.test?.overall_id
                }
                this.$store.dispatch(ADMIN_GET_MARK_HOMEWORK, payload).then((res) => {
                if (!res.error) {
                    this.mark_essay.data = res.data;
                    this.mark_essay.isLoadingMarkEssay = false;
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        markSuccess() {
            this.closeDialogMarkEssay();
            this.showDialogMarkHomework(this.lessonNumber, this.learning_schedule_id);
        },
        closeDialogMarkEssay() {
            this.isMarkEssay = false;
        },

        // checkbox attendance, get data submit, and if check select all use can submit
        changeAttendance(type, course_student_id) {
            this.isSubmitAttendance = false;
            this.attendanceSubmit.data.filter((value) => {
                if (value.course_student_id == parseInt(course_student_id)) {
                    value.val = type;
                }
            })
            let count_student_active = this.attendanceList.filter(element => element.status_class == 0).length;
            let count_student_click = this.attendanceSubmit.data.filter(element => (element.val || element.val == 0)).length;
            // điểm danh lại
            if(this.isAttendanceAgain) {
                this.isSubmitAttendance = true;
                if(count_student_click) {
                    this.isSubmitAttendance = false;
                }
            } else {
                // điểm danh bình thường
                if (count_student_click >= count_student_active) {
                    this.isSubmitAttendance = false;
                } else {
                    this.isSubmitAttendance = true;
                }
            }

        },
        clearDataSubmitAttendance() {
            this.isSubmitAttendance = true;
            this.attendanceSubmit.data = [];
            this.clickAttendanceAgain = false;
        },

        // show modal attendance student
        showModalAttendance(lesson, greaterComingLesson) {
            this.isAttendanceAgain = false;
            this.show_edit = false;
            this.isEditAttendance = true;
            this.clearDataSubmitAttendance();
            this.loadingAttendance = true;
            this.isShowModalAttendance = true;
            this.isAttendance = true;
            let idclass = this.$route.params.id;
            this.startTimeLesson = lesson.date_only;
            this.starTime = lesson.start_time;
            this.lessonNumber = lesson.lesson;
            this.lesson = lesson.lesson;
            this.lessonType = lesson.type;
            this.greaterComingLesson = greaterComingLesson;
            this.$store.dispatch(ATTENDANCE_LESSION, { classroom_id: idclass, lesson: this.lessonNumber }).then((res) => {
                if (!res.error) {
                    this.attendanceList = res.data;
                    this.user_update_object = this.attendanceList[0];
                    this.attendanceList.filter((item) => {
                        this.attendanceSubmit.data.push({ course_student_id: item.id });
                        if (item.attendance) {
                            this.isAttendance = false;
                        }
                        if(!item.user_update) {
                            this.use_update = '';
                        } else {
                            let date_update = moment(this.user_update_object.user_update[0],'HH:mm:ss DD-MM-YYYY');
                            let date_each = moment(item.user_update[0],'HH:mm:ss DD-MM-YYYY');
                            if (date_update.isBefore(date_each)) {
                                this.user_update_object = item;
                            }
                        }
                    })
                    this.use_update = this.user_update_object && this.user_update_object.user_update ? this.user_update_object.user_update[0] + ' bởi '
                     + (this.user_update_object.user_update[1].id + 100000) +
                      ' - ' + this.user_update_object.user_update[1].name : ''

                    this.attendanceList.filter((item) => {
                        if(item.attendance === null && this.isAttendance === false && item.status_class == 0) {
                            this.isAttendanceAgain = true;
                        }

                        if(item.attendance === null  && item.status_class == 0) {
                            this.isEditAttendance = false;
                        }
                    })
                    this.loadingAttendance = false;
                }
            }).catch((e) => {
                console.log(e, 'error')
            });

        },

        // dialog attendance again
        attendanceAgain(){
            this.clickAttendanceAgain = true;
            this.attendanceList = this.attendanceList.filter(item => item.attendance === null);
        },

        // dialog confirm attendance
        attendancePost() {
            this.handleData(false, 'Chỉ có thể điểm danh một lần duy nhất. Bạn có chắc chắn muốn lưu kết quả điểm danh này?');
            return;

        },


        editAttendancePost() {
            let checkAllAttendace = this.checkAllAttendace();
            if(!checkAllAttendace) {
                return false;
            }
            this.handleData(true, 'Bạn có chắc chắn muốn sửa kết quả điểm danh không?');
        },


        checkAllAttendace() {
            var result = true;
            this.attendanceSubmit.data.forEach(d => {
                if (d.val === null) {
                    this.$message({
                        message: 'Vui lòng điểm danh tất cả học sinh!',
                        type: 'error'
                    });
                    result = false;
                }
            });
            return result;
        },


        // close dialog attendance
        closeDialogAttendance() {
            this.isShowModalAttendance = false;
            this.getLesson();
            this.isAttendanceAgain = false;
            this.show_edit = false;
            this.isEditAttendance = true;
        },

        // get payload when submit attendance student
        getPayloadAttendance() {
            this.attendanceSubmit.classroom_id = this.$route.params.id;
            this.attendanceSubmit.lesson = this.lessonNumber;
            this.attendanceSubmit.startTimeLesson = this.startTimeLesson;
            this.attendanceSubmit.starTime = this.starTime;
            this.attendanceSubmit.lesssonType = this.lessonType;
            this.attendanceSubmit.greaterComingLesson = this.greaterComingLesson;
            this.attendanceSubmit.is_show_phase = this.checkShowPhase;
            this.attendanceSubmit.isAttendanceAgain = this.isAttendanceAgain,
            this.attendanceSubmit.show_edit = this.show_edit
        },

        // call api post attendance
        callApiAttendance() {
            this.isLoadingSubmitAttendance = true;
            this.getPayloadAttendance();
            this.attendanceSubmit.data = this.attendanceSubmit.data.filter(d=> d.hasOwnProperty('val'));
            this.attendanceList = this.attendanceList.filter(item => item.attendance === null);
            this.$store
                .dispatch(ACTION_ATTENDANCE_ADMIN, {...this.attendanceSubmit, data: this.attendanceSubmit.data.map(d=>{
                    return {...d, val : d.hasOwnProperty('val') ? d.val:null}
                })})
                .then((res) => {
                    if (!res.error) {
                        if(this.is_edit) {
                            this.$message({
                            message: 'Sửa điểm danh thành công',
                            type: 'success'
                        });
                            }else {
                                this.$message({
                                message: 'Điểm danh thành công',
                                type: 'success'
                            });
                        }

                        this.getLesson();
                        this.closeDialogAttendance();
                        this.is_edit = false;
                        this.isEditAttendance = true;
                        this.isLoadingSubmitAttendance = false;
                    }
                })
                .catch((res) => {
                    if(this.is_edit) {
                            this.$message({
                            message: 'Sửa điểm danh thất bại',
                            type: 'error'
                        });
                    }

                    this.$message({
                        message: res.data.message,
                        type: 'error'
                    });

                    this.closeDialogAttendance();
                    this.isLoadingSubmitAttendance = false;
                });
        },

        beforeOpen() {
            // this.elDialog = true;
        },

        // call api get all lesson of class
        getLesson() {
            let idclass = this.$route.params.id;
            this.$store
                .dispatch(SCHEDULE_CLASS, {
                    limit: 10000,
                    classroom_id: idclass
                })
                .then((res) => {
                    if (!res.error) {
                        let listLessonTest = [];
                        this.lessonList = res.data;
                        console.log(this.lessonList, 'a');
                        listLessonTest = res.data.filter(item => item.type === 'test').map(lesson => lesson.lesson)
                        localStorage.setItem("listTest", JSON.stringify(listLessonTest))
                        localStorage.setItem("listLesson", JSON.stringify(res.data))
                    }
                })
                .catch((e) => {
                    console.log(e, "error");
                });
        },
        openDialogContentHomeWork(lesson) {
            this.contentLesson = lesson;
            this.titleContent = 'Nội dung bài học: ' + lesson.lesson;
            this.dialogContent = true;

        },
        closeDialog() {
            this.dialogContent = false;
        },
        show() {
            this.showHistory = true;

            this.$store.dispatch(GET_TIME_OPEN_SLIDE, {
                classroom_id: this.$route.params.id,
                lesson: this.contentLesson.lesson
            }).then((res) => {
                if (!res.error) {
                    this.dataTimeOpenSlide = res.data;
                }
            });
        },
        handleData(type, title) {
            let filteredArray = this.attendanceList.filter(obj2 => {
                let matchingObj = this.attendanceSubmit.data.find(obj1 => obj2.id === obj1.course_student_id && obj1.val === 2);
                return matchingObj !== undefined;
            });
            this.studentRegister = filteredArray;
            
            if (!Object.values(this.studentRegister).length) {
                if (type) {
                    this.clickAttendanceAgain = false
                    this.is_edit = true;
                    this.isShowModalAttendance = false;
                }
                this.submint(title);
                return;
            }

            this.dialogReason = true;
        },
        submint(title) {
            this.loadingSubmit = true;
            if (!title) {
                title = 'Chỉ có thể điểm danh một lần duy nhất. Bạn có chắc chắn muốn lưu kết quả điểm danh này?';
            }
            let filteredArray = this.attendanceSubmit.data.filter(obj2 => {
                let matchingObj = this.studentRegister.find(obj1 => obj2.course_student_id === obj1.id);
                obj2.reason = matchingObj ? matchingObj.reason : '';
                return obj2;

            });
            let check = this.studentRegister.filter((d) => d.reason == '' || d.reason == null);
            if (Object.values(check).length)
            {
                this.$message({
                    message: 'Điểm danh thất bại',
                    type: 'error'
                });
                this.loadingSubmit = false;
                return;
            }

            this.$confirm(title, {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.callApiAttendance();
                this.loadingSubmit = false;
                this.dialogReason = false;
            });
            console.log(filteredArray, 'filteredArray')
        }
    }
}
</script>


<style lang="scss" scoped>
@import "@/assets/sass/custom/custom-teacher-edu.scss";

.gift .el-dialog {
    width: 60%;
}

@media screen and (max-width: 425.5px) {
    .gift .el-dialog {
        width: 80%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .gift .el-dialog {
        width: 80%;
    }
}
.disable-hard {
    pointer-events: none;
    cursor: not-allowed;
}
</style>
